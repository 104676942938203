import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Edit as EditIcon } from "@mui/icons-material";
import { Alert, Stack, Autocomplete, TextField } from "@mui/material";
import { useMemo, useState, useCallback } from "react";
import { Form, FormSpy } from "react-final-form";
import { useDispatch } from "react-redux";
import { useDisclosure } from "react-use-disclosure";
import FormDateInput from "../../../components/form/FormDateInput";
import FormInput from "../../../components/form/FormInput";
import FormTimeInput from "../../../components/form/FormTimeInput";
import ModalFrame from "../../../components/form/ModalFrame";
import TableIconButton from "../../../components/TableIconButton";
import { setSnackBar } from "../../../redux/snackbar";
import React from "react";
import FormSlider from "../../../components/form/FormSlider";
import FormAddress from "../../../components/form/FormAddress";

const GET_PROVIDERS = gql`
    query GetProviders($lat: Float!, $lng: Float!, $radius: Float!, $providerCategories: [JSON]) {
        providers: getProvidersInArea(lat: $lat, lng: $lng, radius: $radius, providerCategories: $providerCategories) {
            id
            firstName
            lastName
            profilePicture
            address {
                id
                lat
                lng
                inline
            }
            professionalProfile {
                id
                taskLookupSettings
                professionalCategories {
                    id
                    category {
                        id
                        titleLng
                    }
                    subcategory {
                        id
                        titleLng
                    }
                }
            }
        }
    }
`;

export default function EditPackage({ selectedRows = [], reload }) {

    const packageData = useMemo(() => {
        if (selectedRows?.length !== 1) {
            return null;
        }
        const currentRow = selectedRows[0];
        const workDateTime = currentRow?.workDate ? new Date(currentRow.workDate) : null;

        // Convertir las coordenadas a números
        const address = currentRow?.address ? {
            ...currentRow.address,
            lat: parseFloat(currentRow.address.lat),
            lng: parseFloat(currentRow.address.lng)
        } : null;

        const result = {
            id: currentRow?.id,
            email: currentRow?.owner?.email,
            workDate: workDateTime,
            workTime: workDateTime,
            firstName: currentRow?.owner?.firstName,
            lastName: currentRow?.owner?.lastName,
            branch: currentRow?.branch,
            seller: currentRow?.seller,
            dni: currentRow?.dni,
            externalId: currentRow?.externalId,
            title: currentRow?.title,
            description: currentRow?.description,
            price: currentRow?.price,
            revenueRate: currentRow?.revenueRate,
            totalPrice: currentRow?.totalPrice,
            userTerms: currentRow?.userTerms,
            providerTerms: currentRow?.providerTerms,
            adminNotes: currentRow?.adminNotes,
            address: address,
            categoryId: currentRow?.categoryId,
            subcategoryId: currentRow?.subcategoryId,
            media: currentRow?.media,
            acquisitionMethodId: currentRow?.acquisitionMethodId,
			ownerId: currentRow?.owner?.id,
            searchRadius: 80,
            providerId: currentRow?.providerId,
			addressId: currentRow?.address?.id
        };
        return result;
    }, [selectedRows]);
	
	const { isOpen, open, close } = useDisclosure(false);
	const dispatch = useDispatch();

	const GQLCreatePackage = gql`
		mutation updatePackage(
			$ownerEmail: String!
			$address: JSONObject!
			$title: String!
			$description: String!
			$categoryId: Int!
			$subcategoryId: Int
			$price: Float!
			$userTerms: String
			$providerTerms: String
			$workDate: DateTime!
			$media: [JSONObject]
			$acquisitionMethodId: Int
			$externalId: String
			$totalPrice: Float!
			$adminNotes: String
			$revenueRate: Float
			$ownerFirstName: String!
			$ownerLastName: String!
			$branch: String
			$seller: String
			$dni: String
			$providerId: Int
			$id: Int!
			$ownerId: Int
			$addressId: Int
		) {
			createdPackage: updatePackage(
				ownerEmail: $ownerEmail
				address: $address
				title: $title
				description: $description
				categoryId: $categoryId
				subcategoryId: $subcategoryId
				price: $price
				userTerms: $userTerms
				providerTerms: $providerTerms
				workDate: $workDate
				media: $media
				acquisitionMethodId: $acquisitionMethodId
				externalId: $externalId
				totalPrice: $totalPrice
				adminNotes: $adminNotes
				revenueRate: $revenueRate
				ownerFirstName: $ownerFirstName
				ownerLastName: $ownerLastName
				branch: $branch
				seller: $seller
				dni: $dni
				providerId: $providerId
				id: $id
				ownerId: $ownerId
				addressId: $addressId
			) {
				id
			}
		}
	`;

	const GQLValidateUserData = gql`
		query validateUserData(
			$email: String
			$firstName: String
			$lastName: String
			$ownerId: Int
		) {
			validateUserData: validateUserData(
				email: $email
				firstName: $firstName
				lastName: $lastName
				ownerId: $ownerId
			) {
				email
				firstName
				lastName
				ownerId
			}
		}
	`;

	const [CreatePackage] = useMutation(GQLCreatePackage, { fetchPolicy: "network-only" });
	const [ValidateUserData] = useLazyQuery(GQLValidateUserData, { fetchPolicy: "network-only" });
	const [isValidating, setIsValidating] = useState(false);
	const [messageWarning, setMessageWarning] = useState(null);

	const [getProviders, { data: providersData, loading }] = useLazyQuery(GET_PROVIDERS, { 
		fetchPolicy: "network-only" 
	});
	const lastAddress = React.useRef(null);

	const [selectedProvider, setSelectedProvider] = useState(null);

	// eslint-disable-next-line
	const [formRef, setFormRef] = useState(null);

	const createTaskInApp = async (pkg) => {
		if (!isValidating) {
			const {
				ownerId,
				email,
				firstName,
				lastName,
			} = pkg;
			
			const { data: { validateUserData } = {} } = await ValidateUserData({
				variables: {
					email,
					firstName,
					lastName,
					ownerId
				},
			});

			let messageWarning = null;
			
			// Check if email is being changed
			if (email !== packageData.email) {
				if (validateUserData.email) {
					// Email exists in the system
					messageWarning = "El email ingresado ya está registrado. Se actualizará el ID de la cuenta con los datos correspondientes.";
				} else {
					// Email doesn't exist in the system
					messageWarning = "¿Está seguro que desea cambiar el email de este usuario?";
				}
			} else if (firstName !== packageData.firstName || lastName !== packageData.lastName) {
				messageWarning = "¿Está seguro que desea cambiar los datos de esta cuenta?";
			}

			if (messageWarning) {
				setMessageWarning(messageWarning);
				setIsValidating(true);
				return;
			}
		}
	
		setIsValidating(false);
		setMessageWarning(null);
		await processPackageUpdate(pkg);
	};

	const processPackageUpdate = async (pkg) => {
		try {
			const { workDate, workTime, searchRadius, ...restPkg } = pkg;
			
			// Ensure workDate is a valid date
			const finalWorkDate = new Date(workDate);
			if (workTime) {
				finalWorkDate.setHours(workTime.getHours());
				finalWorkDate.setMinutes(workTime.getMinutes());
			}

			// Prepare variables ensuring all required fields are present and in correct format
			const variables = {
				...restPkg,
				id: parseInt(pkg.id),
				workDate: finalWorkDate.toISOString(),
				ownerEmail: pkg.email,
				ownerFirstName: pkg.firstName,
				ownerLastName: pkg.lastName,
				title: pkg.title || '',
				description: pkg.description || '',
				price: parseFloat(pkg.price) || 0,
				revenueRate: parseFloat(pkg.revenueRate) || 0,
				totalPrice: parseFloat(pkg.totalPrice) || 0,
				address: pkg.address || {},
				categoryId: parseInt(pkg.categoryId),
				subcategoryId: pkg.subcategoryId ? parseInt(pkg.subcategoryId) : null,
				branch: pkg.branch || '',
				seller: pkg.seller || '',
				dni: pkg.dni || '',
				externalId: pkg.externalId || '',
				providerId: pkg.providerId ? parseInt(pkg.providerId) : null,
				media: pkg.media || [],
				ownerId: pkg.ownerId ? parseInt(pkg.ownerId) : null,
			};

			const { data: { createdPackage } = {}, errors } = await CreatePackage({
				variables
			});

			if (errors || !createdPackage) {
				dispatch(setSnackBar({ text: "Ocurrió un error al actualizar el paquete.", severity: "error" }));
			} else if (createdPackage) {
				dispatch(setSnackBar({ text: "Tarea actualizada correctamente." }));
				close();
				if (reload) {
                    reload();
                }
			}
		} catch (error) {
			console.error('Error updating package:', error);
			dispatch(setSnackBar({ 
				text: "Error al actualizar el paquete: " + (error.message || "Error desconocido"), 
				severity: "error" 
			}));
		}
	};
	const handleChangeValidation  = (name) => {
		setMessageWarning(null);
		setIsValidating(false);
	}

	const validate = (values) => {
		const errors = {};
		// fillable fields
		if (!values.workDate) errors.workDate = "Required";
		if (!values.workTime) errors.workTime = "Required";
		if (!values.email) errors.email = "Required";

		// auto filled fields
		if (!values.title) errors.title = "Required";
		if (!values.firstName) errors.firstName = "Required";
		if (!values.lastName) errors.lastName = "Required";

		if (!values.description) errors.description = "Required";
		if (!values.price) errors.price = "Required";
		if (!values.revenueRate) errors.revenueRate = "Required";
		if (!values.totalPrice) errors.totalPrice = "Required";

		return errors;
	};

	const loadProviders = useCallback((lat, lng, radius) => {
		const numLat = parseFloat(lat);
		const numLng = parseFloat(lng);

		const addressKey = `${numLat},${numLng},${radius}`;
		
		if (lastAddress.current === addressKey) {
			return;
		}
		
		if (isOpen && !isNaN(numLat) && !isNaN(numLng)) {
			lastAddress.current = addressKey;

			// Construir el array de providerCategories a partir de selectedRows
			const providerCategories = selectedRows.map(row => ({
				categoryId: row.categoryId,
				subcategoryId: row.subcategoryId
			}));

			getProviders({
				variables: {
					lat: numLat,
					lng: numLng,
					radius: radius || 80,
					providerCategories
				}
			}).catch(error => {
				console.error('Error fetching providers:', error);
			});
		}
	}, [isOpen, getProviders, selectedRows]);

	React.useEffect(() => {
		if (isOpen && selectedRows[0]?.address?.lat && selectedRows[0]?.address?.lng) {
			loadProviders(
				selectedRows[0].address.lat,
				selectedRows[0].address.lng,
				packageData?.searchRadius || 80
			);
		}
	}, [isOpen, selectedRows, loadProviders, packageData?.searchRadius]);

	React.useEffect(() => {
		if (providersData?.providers && packageData?.providerId) {
			const provider = providersData.providers.find(p => parseInt(p.id) === parseInt(packageData.providerId));
			if (provider) {
				setSelectedProvider(prevProvider => {
					if (prevProvider?.id !== provider.id) {
						// Si el proveedor tiene dirección y el formulario está disponible
						if (provider.address && formRef) {
							formRef.change('address', {
								...provider.address,
								inline: provider.address.inline || '',
								lat: provider.address.lat,
								lng: provider.address.lng
							});
						}
						return provider;
					}
					return prevProvider;
				});
			}
		}
	}, [providersData?.providers, packageData?.providerId, formRef]);

	return (
		<>
			<TableIconButton 
				onClick={() => {
					setIsValidating(false);
					open();
				}} 
				disabled={!packageData} 
				icon={<EditIcon />} 
				tooltip="Editar tarea empaquetada" 
			/>
			
			<Form
				onSubmit={createTaskInApp}
				initialValues={{ 
					...packageData,
					searchRadius: packageData?.searchRadius ?? 80 // Valor por defecto si packageData es null
				}}
				validate={validate}
				render={({ handleSubmit, values = {}, valid, form }) => {
					return (
						<ModalFrame
							onSubmit={handleSubmit}
							{...{ isOpen, close }}
							title={
								<Stack direction={"column"} gap={2}>
									<span>Editar paquete {packageData?.id}</span>
									{messageWarning && <Alert severity="warning">{messageWarning}</Alert>}
								</Stack>
							}
							submitDisabled={!valid}
							buttonTexts={["Enviar", "Cancelar"]}>
							<Stack spacing={4}>
							<Stack direction={"row"} gap={2}>
								<FormInput label="Email" name="email" autoFocus required onChange={handleChangeValidation}/>
								<FormDateInput name="workDate" label="Fecha del trabajo" disablePast required />
								<FormTimeInput name="workTime" label="Hora del trabajo" required />
							</Stack>
							<Stack direction={"row"} gap={2}>
								<FormInput label="Nombre del Cliente" name="firstName" required onChange={handleChangeValidation}/>
								<FormInput label="Apellido del Cliente" name="lastName" required onChange={handleChangeValidation}/>
							</Stack>
							<Stack direction={"row"} gap={2}>
								<FormInput label="Sucursal" name="branch" />
								<FormInput label="Vendedor" name="seller" />
							</Stack>
							<Stack direction={"row"} gap={2}>
								<FormInput label="dni / factura" name="dni"  />
								<FormInput label="Id Externo" name="externalId" required />
							</Stack>

							<FormAddress 
								label="Direccion" 
								name="address" 
								required
							/>
							<Stack direction="row" gap={2}>
								<Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
									<FormSlider 
										name="searchRadius" 
										label={`Radio de búsqueda: ${values.searchRadius} km`}
										min={1} 
										max={80}
										disabled={!values.address?.lat || !values.address?.lng}
									/>
									<FormSpy subscription={{ values: true }}>
									{({ values }) => {
										if (values.address?.lat && values.address?.lng) {
											loadProviders(values.address.lat, values.address.lng, values.searchRadius);
										}
										return null;
									}}
								</FormSpy>
								</Stack>

								<Autocomplete
									sx={{ flex: 2 }}
									options={providersData?.providers || []}
									getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
									value={selectedProvider}
									isOptionEqualToValue={(option, value) => parseInt(option.id) === parseInt(value.id)}
									renderInput={(params) => (
										<TextField 
											{...params} 
											label="Experto" 
											helperText={loading ? "Cargando expertos..." : "Escriba para filtrar expertos"}
										/>
									)}
									onChange={(_, newValue) => {
										setSelectedProvider(newValue);
										form.change('providerId', newValue?.id || null);
									}}
									filterOptions={(options, { inputValue }) => {
										const searchText = inputValue.toLowerCase();
										return options.filter(
											option => 
												option.firstName.toLowerCase().includes(searchText) ||
												option.lastName.toLowerCase().includes(searchText)
										);
									}}
									loading={loading}
									noOptionsText="No se encontraron expertos"
								/>
							</Stack>
							<Stack direction="row" gap={2}>
								<FormInput label="Notas" name="adminNotes" type="textarea" />
							</Stack>

							<Stack direction="row" gap={2}>
								<FormInput label="Título" name="title" required />
								<FormInput label="Importe a abonar al experto" name="price" type="number" required fullWidth={false} />
								<FormInput label="% de Ganancia" name="revenueRate" type="number" required fullWidth={false} />
							</Stack>
							<Stack direction="row" gap={2}>
								<FormInput label="Descripcion" name="description" required />
								<FormInput label="Importe cobrado al usuario" name="totalPrice" type="number" required fullWidth={false} />
							</Stack>
							<Stack direction="row" spacing={2}>
								<FormInput label="TyC Usuario" name="userTerms" multiline rows={8} />
								<FormInput label="TyC Expertos" name="providerTerms" multiline rows={8} />
							</Stack>
							
						</Stack>
						
					</ModalFrame>
				);
			}}
			/>
		</>
	);
}

