import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import Dashboard from './screens/dashboardTemplate';
import App from './screens/app';
import Reports from './screens/reports';
import SignIn from './screens/signIn';
import TasksList from './screens/tasksList';
import UsersList from './screens/usersList';
import ProviderList from './screens/providersList';
import ProviderListByUpdate from './screens/providersListByUpdate';
import TaskActivityList from './screens/taskActivityList';
import TestScreen from './screens/testScreen';
import MessagesList from './screens/messagesList';
import BudgetsList from './screens/budgetsList';
import CommissionList from './screens/commissions';
import QualificationsScreen from './screens/qualifications';
import PromotionCodesList from './screens/promotionCodesList';
import Gallery from './screens/gallery';
import ProviderChipsList from './screens/providerChips';
import AcquisitionMethods from './screens/acquisitionMethods';
import BannerScreen from './screens/bannerScreen';
import BundleTasks from './screens/bundleTasks';
import Categories from './screens/categories';
import Subcategories from './screens/subcategories';
import UserDetail from './screens/userDetail';
import TaskDetail from './screens/taskDetail';
import AllowedAreas from './screens/allowedAreas';
import Pakcages from './screens/packages';
import PackageDetail from './screens/packageDetail';
import PakcagesByWorkDate from './screens/packagesByWorkDate';
import PackageFinances from './screens/packageFinances';
import PackageMessagesList from './screens/packageMessagesList';
import ProvidersMap from './screens/providersMap';
import ReportingScreen from './screens/reportingScreen';

function MainRouter() {

  return <BrowserRouter>
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/*" element={<App />}>
        <Route exact path="reports" element={<Reports />} />
        <Route exact path="test" element={<TestScreen />} />
        <Route exact path="users" element={<UsersList />} />
        <Route exact path="providers" element={<ProviderList />} />
        <Route exact path="providersByUpdate" element={<ProviderListByUpdate />} />
        <Route exact path="tasks" element={<TasksList />} />
        <Route exact path="reporting" element={<ReportingScreen />} />
        <Route exact path="budgets" element={<BudgetsList />} />
        <Route exact path="qualifications" element={<QualificationsScreen />} />
        <Route exact path="taskActivities" element={<TaskActivityList />} />
        <Route exact path="messages" element={<MessagesList />} />
        <Route exact path="package-messages" element={<PackageMessagesList />} />
        <Route exact path="commissions" element={<CommissionList />} />
        <Route exact path="promotion-codes" element={<PromotionCodesList />} />
        <Route exact path="gallery" element={<Gallery />} />
        <Route exact path="provider-chips" element={<ProviderChipsList />} />
        <Route exact path="acquisition-methods" element={<AcquisitionMethods />} />
        <Route exact path="banners" element={<BannerScreen />} />
        <Route exact path="bundle-tasks" element={<BundleTasks />} />
        <Route exact path="categories" element={<Categories />} />
        <Route exact path="subcategories" element={<Subcategories />} />
        <Route exact path='packages' element={<Pakcages />} />
        <Route exact path='packagesByWorkDate' element={<PakcagesByWorkDate />} />
        <Route exact path='packageFinances' element={<PackageFinances />} />

        <Route exact path='users/:id' element={<UserDetail />} />
        <Route exact path='task/:id' element={<TaskDetail />} />
        <Route exact path='package/:id' element={<PackageDetail />} />
        <Route exact path='allowedAreas' element={<AllowedAreas />} />
        <Route exact path='providersMap' element={<ProvidersMap />} />
        <Route exact path="*" element={<Dashboard />} />
      </Route>
    </Routes>
  </BrowserRouter>
}

export default MainRouter;
