import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import {
    Dashboard,
    People,
    Style,
    Chat,
    AlarmOn,
    AssignmentTurnedIn,
    StarRate,
    Engineering,
    LocalAtm,
    Collections,
    TaskAlt,
    LocalOffer,
    Category,
    Map,
    LocalShipping,
    Panorama,
    Today,
    BarChart
} from '@mui/icons-material';

import MannoLogo from './logo.svg';
import { NavItem } from './nav-item';
import { getCurrentBoUser } from '../GQLQueries';
import { useQuery } from '@apollo/client';
import { NavGroup } from './navGroup';

const createNavLink = (href, title, icon) => ({ href, icon, title })
const createNavGroup = (title, items) => ({ title, items })

const drawItem = (item) => {

    if (item.items) {
        const active = item.items.some(({ href }) => href ? (window.location.pathname === href) : false);
        return (
            <NavGroup key={`${item.title}-group`} {...item} initialOpen={active}>
                {item.items.map((subItem) => drawItem(subItem))}
            </NavGroup>
        )
    }
    return <NavItem key={item.title} icon={item.icon} href={item.href} title={item.title} />
}

const iconOptions = { fontSize: "small" }
const items = [
    createNavLink('/', 'Dashboard', <Dashboard {...iconOptions} />),

    createNavGroup('Reportes', [
        createNavLink('/tasks', 'Tareas', <Style {...iconOptions} />),
        createNavLink('/reporting', 'Reportes', <BarChart {...iconOptions} />),
        createNavLink('/packages', 'Paquetes creados', <LocalShipping {...iconOptions} />),
        createNavLink('/packagesByWorkDate', 'Paquetes agendados', <Today {...iconOptions} />),
        createNavLink('/packageFinances', 'Finanzas de paquetes', <LocalAtm {...iconOptions} />),
        createNavLink('/users', 'Usuarios', <People {...iconOptions} />),
        createNavLink('/providers', 'Expertos', <Engineering {...iconOptions} />),
        createNavLink('/providersByUpdate', 'Expertos por edicion', <Engineering {...iconOptions} />),
        createNavLink('/budgets', 'Presupuestos', <AssignmentTurnedIn {...iconOptions} />),
        createNavLink('/qualifications', 'Calificaciones', <StarRate {...iconOptions} />),
        createNavLink('/taskActivities', 'Actividad de las tareas', <AlarmOn {...iconOptions} />),
        createNavLink('/messages', 'Mensajes de tareas', <Chat {...iconOptions} />),
        createNavLink('/package-messages', 'Mensajes de paquetes', <Chat {...iconOptions} />),
        createNavLink('/commissions', 'Comisiones', <LocalAtm {...iconOptions} />),
        createNavLink('/providersMap', 'Mapa de expertos', <Map {...iconOptions} />),
    ]),
    createNavGroup('Configuracion', [
        createNavLink('/bundle-tasks', 'Paquetes', <LocalShipping {...iconOptions} />),
        createNavLink('/categories', 'Categorias', <Category {...iconOptions} />),
        createNavLink('/subcategories', 'Subcategorias', <Category {...iconOptions} />),
        createNavLink('/acquisition-methods', 'Metodos de adquisicion', <TaskAlt {...iconOptions} />),
        createNavLink('/promotion-codes', 'Codigos de promocion', <LocalOffer {...iconOptions} />),
        createNavLink('/banners', 'Banners', <Panorama {...iconOptions} />),
        createNavLink('/provider-chips', 'Chips', <TaskAlt {...iconOptions} />),
        createNavLink('/gallery', 'Galería multimedia', <Collections {...iconOptions} />),
        createNavLink('/allowedAreas', 'Areas habilitadas', <Map {...iconOptions} />),
    ]),
];

export const DashboardSidebar = (props) => {
    const { open, onClose } = props;
    const router = {};
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), { defaultMatches: true, noSsr: false });
    const pathname = window.location.pathname
    useEffect(
        () => {
            if (!router.isReady) return;
            if (open) onClose?.();
        }, [pathname, router.isReady, open, onClose]
    );

    const { data } = useQuery(getCurrentBoUser);

    let { firstName, lastName, email } = (data?.getMyBoUser || {})

    const content = <>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div>
                <Box sx={{ p: 3 }}>
                    <Link to="/" ><img style={{ height: 42 }} src={MannoLogo} alt="Manno" /></Link>
                </Box>
                <Box sx={{ px: 2 }}>
                    <div>
                        <Typography color="inherit" variant="subtitle1">{firstName} {lastName}</Typography>
                        <Typography color="neutral.400" variant="body2">{email}</Typography>
                    </div>
                </Box>
            </div>
            <Divider
                sx={{
                    borderColor: '#2D3748',
                    my: 3
                }}
            />
            <Box sx={{ flexGrow: 1 }}>
                {/* {items.map((item) => <NavItem key={item.title} icon={item.icon} href={item.href} title={item.title} />)} */}
                {items.map((item) => drawItem(item))}
            </Box>
        </Box>
    </>

    if (lgUp) return <Drawer anchor="left" open PaperProps={{ sx: { backgroundColor: 'neutral.900', color: '#FFFFFF', width: props.width } }} variant="persistent">{content}</Drawer>

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};
export default DashboardSidebar